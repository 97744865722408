export const InGame = {
  name: 'InGame',
  path: '/InGame',
  component: () => import('@/views/member/game/'),
  redirect: {
    name: 'InGame2'
  },
  meta: {
    layout: 'default',
    text: '인게임'
  },
  children: [
    {
      name: 'InGame2',
      path: '/InGame/2',
      component: () => import('@/views/member/game/ingame/InGame2')
    }
  ]
}
