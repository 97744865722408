import httpClient from '@/libs/http-client'

export function bettingList (params) {
  const url = '/api/bet/betlist'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getSportBettingList (params) {
  const url = '/api/bet/sportBetlist'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
