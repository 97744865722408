<template>
  <div :class="{'holdem' : $route.name === 'holdem'}">
    <router-view :key="$route.fullPath"></router-view>
  </div>
  <loading></loading>
</template>

<script>
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Loading
  }
}
</script>

<style>
.holdem {
  background: #000;
  height: 100vh;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}

</style>
