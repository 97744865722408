export const holdem = {
  name: 'holdem',
  path: '/holdem',
  component: () => import('@/views/member/game/holdem/holdemMain'),
  props: true,
  meta: {
    layout: 'mobile-empty',
    text: '파워볼',
    gnb: false
  }
}
