import httpClient from '@/libs/http-client'

// 테스트용 링크
export function getGameOld (params) {
  const url = '/api/minigame/getGameOld'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGame (params) {
  const url = '/api/minigame/getGame'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameTriple (params) {
  const url = '/api/minigame/getGameTriple'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameSlotList (params) {
  const url = '/api/minigame/getSlotList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getSlotGame (params) {
  const url = '/api/minigame/slots/' + params.prodId + '/' + params.type
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function powerballBetting (params) {
  const url = '/api/bet/betProc'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function powerballResult (params) {
  const url = '/api/minigame/powerballResult'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getHoldemMoney (params) {
  const url = '/api/next/userBalance'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getHoldemList (params) {
  const url = '/api/cash/list'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function chargeHolem (params) {
  const url = '/api/next/userDeposit'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function exchangeHolem (params) {
  const url = '/api/next/userWithdraw'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
